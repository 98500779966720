import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 2";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "homepage"
    }}>{`Homepage`}</h1>
    <p>{`Every project starts with a landing page. It gives you structure and somewhere to start.`}</p>
    <p>{`A landing page answers the question: Why does this exist and what does it do?`}</p>
    <p>{`Create one in `}<inlineCode parentName="p">{`src/pages/index.js`}</inlineCode>{`. Try out Rebass's responsive features.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/1ff84/homepage.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.600896860986545%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'170\\'%20viewBox=\\'0%200%20400%20170\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M22%2035l-1%209c0%207%200%208%202%208l1-6c0-6%201-6%204%201%202%206%203%206%206%200%202-6%203-6%203%200l1%205c1%200%202-2%202-9%200-8-1-8-2-8-2%200-3%201-5%206s-3%206-3%204l-3-6c-2-5-4-6-5-4m42%208c0%207%200%209%202%209l1-2%201-3%202%203c2%202%205%203%205%201l-2-3c-2-3-2-4%200-7%203-3%200-3-4%200l-2%202v-4l-1-5c-2%200-2%202-2%209m21-6c0%202%200%203-3%202-4%200-6%201-6%206s2%207%207%207h5v-9l-1-9c-1%200-2%201-2%203m106%200c0%202%200%203-3%202-3%200-6%202-6%207%200%204%203%206%208%206h5v-9c0-7-1-9-2-9-2%200-2%201-2%203m45%206c0%208%200%209%202%209s2-1%202-3c0-3%200-3%203-3%206%200%208-7%204-10l-7-2h-4v9M43%2041c-2%202%200%203%203%201h3c1%201%200%202-2%202-8%201-7%208%201%208h5v-5l-1-7c-2-1-8-1-9%201m49%200c-4%204-1%2011%204%2011%208%200%209-12%201-13-3%200-4%200-5%202m13%204c2%208%205%209%206%203l2-4%201%204c2%206%204%205%206-3%202-6%202-6%200-6l-3%204v5l-1-4c-2-6-5-6-6-1l-1%205-1-5-3-4c-2%200-2%200%200%206m18%201c0%205%200%206%202%206%201%200%202-2%202-5%200-4%200-5%202-5s3%203%203%207l1%203c2%200%202-1%202-5%200-7-1-8-7-8h-5v7m34-6v3l3-1%202-1c2%202%201%203-1%203-3%200-6%202-6%204%200%203%202%204%207%204%204%200%205-1%205-4%200-8-3-11-10-8m61%201c-2%202-2%206%200%209v2c-2%200-1%203%201%204%203%201%208%200%209-2l1-8v-7h-5l-6%202m33%200c-2%202%200%203%202%201%203-1%205-1%205%201l-3%201c-3%200-5%202-5%205%200%202%201%203%208%203h3v-5l-1-7c-2-1-8-1-9%201m13%200c-3%206%202%2014%207%2010%201-1%201-1%201%201-1%201-4%202-6%201-3-1-2%202%200%203h6c2-1%203-2%203-9v-8h-5c-4%200-5%200-6%202m15%200c-5%207%201%2014%208%2010%203-2%202-3-2-2l-4-1c0-2%201-2%204-2%204%200%205-1%203-5-2-3-7-3-9%200M20%2086v8h45V78H20v8\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/ca0a1/homepage.webp 223w", "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/75680/homepage.webp 445w", "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/8d1ba/homepage.webp 890w", "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/02506/homepage.webp 1040w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/e92b6/homepage.png 223w", "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/e66bf/homepage.png 445w", "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/4ef49/homepage.png 890w", "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/1ff84/homepage.png 1040w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2db2ce2dce0bea8e3cb437cdd8be8d6e/4ef49/homepage.png",
              "alt": "homepage",
              "title": "homepage",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "add-a-login-button"
    }}>{`Add a login button`}</h2>
    <p>{`The login button should be a clear call-to-action so users know where to start.`}</p>
    <p>{`Make it so the login button lets you logout as well.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      